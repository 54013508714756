<template>
	<div v-if="dialogVisible">
		<el-dialog title="提案征集通知详情" :visible.sync="dialogVisible" width="40%">
			<div class="addbox">
				<div class="left_info">
					<div style="width: 100%;text-align: center;font-size: 24px;margin-bottom: 20px;">
						{{info.name}}
					</div>
					<div style="width: 100%;text-align: right;margin-bottom: 10px;">
						{{info.fb_user}}
						<p>
							{{info.fb_time}}
						</p>
					</div>
					<div style="width: 100%;height: 50vh;" class="myhtml" v-html="info.content">
						
					</div>					
					<div style="width: 100%;margin-bottom: 10px;">
						截止时间：{{info.endtime}}
					</div>					
					<div style="width: 100%;display: flex;">
						<div>附件列表：</div>
						<div style="width: 80%;">
							<div v-for="(item2,index2) in info.fujian" :key="index2" style="color: #409EFF;margin-bottom: 5px;cursor: pointer;width: 80%;overflow: hidden;text-overflow: ellipsis;white-space: nowrap;" @click="open(item2.fullurl)">
								{{item2.filename}}
							</div>
						</div>
					</div>
				</div>
			</div>
			<div class="btnbox">
				<el-button type="" size="mini" class="font14" @click="dialogVisible=false">关闭</el-button>
			</div>
		</el-dialog>

	</div>

</template>

<script>
	export default {
		data() {
			return {
				dialogVisible: false,
				info:''
			}
		},
		methods: {
			open(url){
				window.open(url,'_blank')
			}
		}
	}
</script>

<style lang="scss" scoped>
	.item_line {
		display: flex;
		align-items: center;
		margin-bottom: 30px;

		.tlename {
			width: 120px;
			font-size: 14px;

			text-align: right;
			color: #333;
		}

		.bticon {
			width: 10px;
			height: 20px;
			display: inline-block;
			vertical-align: middle;
			text-align: center;
			line-height: 34px;
			font-size: 26px;
			color: red;
		}
	}

	.infobox {
		width: 100%;
		padding-left: 50px;
		padding-right: 30px;

		.contbox {
			display: flex;
			padding: 20px 40px;
			height: 750px;
			transition: all 0.5s;
			overflow: hidden;
		}

		.colosecont {
			height: 0;
			padding: 0;
		}

		.title {
			width: 100%;
			display: flex;
			align-items: center;
			justify-content: space-between;
			padding: 14px 20px;
			color: #ec4a4a;
			border-bottom: 1px solid #ec4a4a;
		}
	}

	.addbox {
		width: 100%;
		overflow-y: auto;
		padding: 30px;
		background-color: #fff;

	}

	.add_cont {
		width: 100%;
		background-color: #fff;
		padding: 10px;
	}

	.btnbox {
		display: flex;
		align-items: center;
		justify-content: flex-end;
		padding: 10px 0;
		border-top: 1px solid #eee;
	}

	

	::v-deep .el-dialog {
		margin: 0 !important;
	}

	::v-deep .el-dialog__header {
		border-bottom: 1px solid #eee;
	}

	::v-deep .el-dialog__footer {
		border-top: 1px solid #eee;
	}

	::v-deep .el-dialog__body {
		padding: 10px;
		padding-top: 0px;
	}

	::v-deep .el-tabs--border-card {
		height: 100%;
	}

	::v-deep .el-tabs--border-card {
		box-shadow: 0 0 0 #fff;
	}

	::v-deep .el-tabs__header {
		background-color: #fff;
	}

	::v-deep .avatar-uploader .el-upload {
		border: 1px dashed #d9d9d9;
		border-radius: 6px;
		cursor: pointer;
		position: relative;
		overflow: hidden;
	}

	.avatar-uploader .el-upload:hover {
		border-color: #409EFF;
	}

	.avatar-uploader-icon {
		font-size: 28px;
		color: #8c939d;
		width: 178px;
		height: 178px;
		line-height: 178px;
		text-align: center;
	}

	.avatar {
		width: 178px;
		height: 178px;
		display: block;
	}
</style>